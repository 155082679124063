import { Button } from '@mui/material'
import React from 'react'
import theme from '../../util/theme'
import { useNavigate } from 'react-router-dom';
const Btn = ({ bg, color, text = 'See More', path, wi }) => {
    const navigate = useNavigate()
    return (
        <>
            <Button 
                onClick={() => navigate(path)}
            style={{ background: bg ? bg : theme.palette.secondary.main,
                color: color ? color : theme.palette.primary.main, fontWeight: 'bold', textTransform: 'capitalize', padding: '5px 16px', width:wi }} >
                {text}
            </Button>
        </>
    )
}

export default Btn