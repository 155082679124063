import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import Item from './Item'
import Title from '../glopal/Title'
import { services } from '../../constants'

const OurServices = () => {
    
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ p: 2 }}>
                    <Title title={`Our Services`} />
                    <Grid container >
                        {
                            services?.map(service => 
                        <Grid item md={4} xs={12}>
                            <Item 
                                img={service?.img}
                                title={service?.title}
                                // desc={service?.desc}
                                />
                        </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default OurServices