import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const Item = ({ img, title, desc, color, bgColor }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.7 }} >
            <CardMedia
                component={'img'}
                src={img}
                sx={{ objectFit: 'contain', width: '75px', height: '75px', ml:'5%', mb:'-10%', zIndex:9 }}
            />
            <Box sx={{ border: '5px solid #F3F3F3', background: '#FFF', borderRadius: '8px', p: '10px 0px 10px 10px', transition: '0.5s', ':hover': { p: '0' }, textAlign: 'left' }} >
                <Box sx={{ p: 3, background: '#F3F3F3' }} >
                    <Typography variant='h6' >{title}</Typography>
                    <Typography color={color} sx={{ p:1, borderRadius:1, backgroundColor: bgColor, width:'fit-content', my:1 }}>{desc}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Item