// import { useTheme } from '@emotion/react';
import { Box, CardMedia,} from '@mui/material'
import React from 'react'

const Card = ({ name, rev, revDesc, img }) => {
    // const themeM = useTheme();
    // const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ position: 'relative', m: 2, backgroundColor: '#fff', p: { md: 2, xs: 0 }, borderRadius: '16px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border:'1px solid #ce4114' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: { md: 1, xs: 0 }, my: 1 }} >
                    <CardMedia
                        component={'img'}
                        src={img}
                        sx={{ width: '100%', }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Card