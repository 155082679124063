import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const Card = ({ name, date, meetDesc, img }) => {
    return (
        <>
            <Box sx={{
                position: 'relative', m: 2, backgroundColor: '#F3F3F3', p: 2, borderRadius: '16px', display: 'flex', gap: 3,flexDirection:
                    { md: 'row', xs: 'column' } }} >
                <Box sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', gap:1, my:1 }} >
                    <CardMedia 
                        component={'img'}
                        src={img}
                        sx={{ borderRadius:'50%', width:'100%' }}
                        />
                </Box>
                <Box sx={{ display:'flex', flexDirection:'column', gap:2 }} >
                    <Typography variant='h5' color={'#FABB25'} >{name}</Typography>
                    <Typography variant='h6' >{date}</Typography>
                    <Typography >
                        {meetDesc}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Card