import { Box, Button, InputLabel, Typography } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './form.css'
// import theme from '../../util/theme';
const Form = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service', 'template', form.current, 'user')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <div>
            <Box sx={{ borderRadius: '30px', }}>
                <Typography sx={{ color: '#000', my: 1 }} variant='h5' >Get Quote & Book Now</Typography>
                <Box component={'form'} ref={form} onSubmit={sendEmail} >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} >
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                            <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                First Name
                            </InputLabel>
                            <input
                                // placeholder='First Name'
                                style={{ backgroundColor: '#FFF' }}
                                required
                                id="First Name"
                                variant="filled"
                                size="medium"
                                name='First Name'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Last Name
                            </InputLabel>
                            <input
                                // placeholder='Last Name'
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="Last Name"
                                variant="filled"
                                size="medium"
                                name='Last Name'
                                className='input'
                            />
                        </Box>
                        {/* <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                           <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Message
                            </InputLabel>
                           <input
                            placeholder='Zip Code'
                                style={{ backgroundColor: '#FFF' }}
                                required
                                id="Zip Code"
                                variant="filled"
                                size="medium"
                                name='Zip Code'
                                className='input'
                            />
                        </Box> */}
                        {/* <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                           <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Message
                            </InputLabel>
                           <input
                            placeholder='City'
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="City"
                                variant="filled"
                                size="medium"
                                name='City'
                                className='input'
                            />
                        </Box> */}
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Email
                            </InputLabel>
                            <input
                                // placeholder='Email'
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="Email"
                                variant="filled"
                                size="medium"
                                name='Email'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                            <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Mobile Phone
                            </InputLabel>
                            <input
                                // placeholder='Mobile Phone'
                                style={{ backgroundColor: '#FFF' }}
                                required
                                id="Mobile Phone"
                                variant="filled"
                                size="medium"
                                name='Mobile Phone'
                                className='input'
                            />
                        </Box>
                        {/* <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <input
                            placeholder='Home Phone'
                                required
                                style={{ backgroundColor: '#FFF' }}
                                id="Home Phone"
                                variant="filled"
                                size="medium"
                                name='Home Phone'
                                className='input'
                            />
                        </Box> */}

                        <Box sx={{ width: '100%', my: 2 }}  >
                            <InputLabel sx={{ color: '#000' }} htmlFor="description"  >
                                Message
                            </InputLabel>
                            <textarea
                                // required
                                style={{ backgroundColor: '#FFF', padding: '20px', height: '100px', resize: 'none' }}
                                id="description"
                                variant="filled"
                                aria-multiline
                                size="medium"
                                name='description'
                                className='input'
                            />
                        </Box>
                    </Box>
                    <Box mt={2} >
                        <Button variant="contained"
                            type="submit"
                            sx={{
                                backgroundColor: '#ce4114', ':hover': {
                                    backgroundColor: '#ce4114'
                                },
                                color: "#FFF",
                                fontWeight: 'bold',
                                width: '100px'
                            }}>Send</Button>
                    </Box>
                </Box>
            </Box>
        </div >
    )
}
export default Form
