import { Box, CardMedia } from '@mui/material'
import React from 'react'
import { socials } from '../../../constants'

const ListSocial = () => {
  return (
    <>
    {
        socials?.map((social, i) =>
          <Box key={i} >
            <a href="/" target="_blank" rel="noopener noreferrer" >
                <CardMedia 
                    component={'img'}
                    src={social.src}
                    sx={{ width:'25px', height:'25px', objectFit:'contain' }}
                />
            </a>
        </Box>
        )
    }
    </>
  )
}

export default ListSocial