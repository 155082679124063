import React from 'react'
import Header from '../components/glopal/Header'
import ListMeets from '../components/Meets/ListMeets'

const Meets = () => {
  return (
    <>
        <Header url={'Meets'} />
        <ListMeets />
    </>
  )
}

export default Meets