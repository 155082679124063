import { Box, Button, InputLabel, Typography } from '@mui/material'
import React from 'react'
import './form.css'

const Form = () => {
    return (
        <>

            <Box component={'form'} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', height:'100%' }} >
                <Typography variant='h6' sx={{ color: '#fff', my: 2, width: '100%' }}>Request Call Back</Typography>
                <Box sx={{ width: { md: '45%', xs: '100%' }, }}>
                    <InputLabel sx={{ color: '#FFF' }} htmlFor="name"  >
                        name
                    </InputLabel>
                    <input
                        style={{ backgroundColor: '#FFF' }}
                        required
                        id="name"
                        variant="filled"
                        size="medium"
                        name='name'
                        className='input'
                    />
                </Box>
                <Box sx={{ width: { md: '45%', xs: '100%' }, }}>
                    <InputLabel sx={{ color: '#FFF' }} htmlFor="email"  >
                        Email
                    </InputLabel>
                    <input
                        style={{ backgroundColor: '#FFF' }}
                        required
                        id="email"
                        variant="filled"
                        size="medium"
                        name='email'
                        className='input'
                    />
                </Box>
                <Box sx={{ width: { md: '100%', xs: '100%' }, }}  >
                    <InputLabel sx={{ color: '#FFF' }} htmlFor="phone_number"  >
                        Number Phone
                    </InputLabel>
                    <input
                        required
                        style={{ backgroundColor: '#FFF' }}
                        id="phone_number"
                        variant="filled"
                        size="medium"
                        name='phone_number'
                        className='input'
                    />
                </Box>
                <Box sx={{ width: { md: '100%', xs: '100%' }, }}  >
                    <InputLabel sx={{ color: '#FFF' }} htmlFor="description"  >
                        Message
                    </InputLabel>
                    <textarea
                        // required
                        style={{ backgroundColor: '#FFF', padding: '20px', height: '100px', resize: 'none' }}
                        id="description"
                        variant="filled"
                        aria-multiline
                        size="medium"
                        name='description'
                        className='input'
                    />
                </Box>
                <Box mt={2} >
                    <Button variant="contained"
                        type="submit"
                        sx={{
                            backgroundColor: '#F12020', ':hover': {
                                backgroundColor: '#F12020'
                            },
                            color: "#fff",
                            fontWeight: 'bold',
                        }}>Send Message Now</Button>
                </Box>
            </Box>
        </>
    )
}
export default Form
