import React from 'react'
import SliderHome from '../components/SliderHome'
import OurServices from '../components/OurServices'
import Category from '../components/Category'
import WhyChooseUs from '../components/WhyChooseUs'
import ContactUsHome from '../components/ContactUsHome'
import ListReviews from '../components/Reviews/ListReviews'

const Home = () => {
  return (
    <> 
      <SliderHome />
      <Category />
      <OurServices />
      {/* <WhyChooseUs /> */}
      <ContactUsHome />
      <ListReviews />
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.7009828585146!2d-104.9206799!3d39.6789404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c7de7c2c373c1%3A0x537fa42c9039ad22!2sAurora%20Motors!5e0!3m2!1sen!2seg!4v1712093412844!5m2!1sen!2seg" width="100%" height="600" title={'test'} style={{ border: 0, marginTop: '16px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
    </>
  )
}

export default Home 