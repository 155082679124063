import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const Item = ({ img, title, desc }) => {
    return (
        <>
            <Box sx={{ m: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                <CardMedia
                    sx={{ height: 250, backgroundSize: '100% 100%', width: '100%', borderRadius: '16px', }}
                    image={img}
                    title="green iguana" />
                <Box sx={{ p: 1, backgroundColor: '#fff', borderRadius: '16px', mt: '-2rem', ml: '-2rem', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: 'flex', alignItems: 'flex-start', width: '100%', flexDirection: 'column' }}>
                    <Typography gutterBottom color={'#ce4114'} component="div" sx={{ my:1 }}>
                        {title}
                    </Typography>
                </Box>
                {/* <Typography gutterBottom color={'#939494'} component="div" sx={{ my:1 }}>
                        {desc}
                    </Typography> */}
            </Box>
        </>
    )
}

export default Item