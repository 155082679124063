import React from 'react'
import Header from '../components/glopal/Header'
import Details from '../components/RebuiltTitles/Details'
const RebuiltTitles = () => {
    return (
        <>
            <Header url={'Rebuilt Titles'} />
            <Details />
        </>
    )
}

export default RebuiltTitles