import React from 'react'
// import Head from '../components/Services/Head'
import ListServices from '../components/Services/ListServices'
// import { useTheme } from '@emotion/react';
// import { useMediaQuery } from '@mui/material';
import Header from '../components/glopal/Header';

const Services = () => {
  // const themeM = useTheme();
  // const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
  
  return (
    <>
      <Header url={'Reviews'} />
        <ListServices />
    </>
  )
}

export default Services