// import { useTheme } from '@emotion/react';
import { Box, Container, Grid, } from '@mui/material'
import React from 'react'
import Form from '../Form/Form';
import Item from '../AboutUs/Item';
import { catAbout } from '../../constants';
// InputLabel, MenuItem, Select,
const FormContactPage = () => {
    // const themeM = useTheme();
    // const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    // const [brand, setBrand] = React.useState('');

    // const handleChange = (event) => {
    //     setBrand(event.target.value);
    // }
    // const [model, setModel] = React.useState('');

    // const handleChangeModel = (event) => {
    //     setModel(event.target.value);
    // };
    return (
        <>
            <Box sx={{ py:2 }}>
                <Container maxWidth={'lg'}>
                    <Grid container >
                        <Grid item md={6} sm={6} xs={12} sx={{ my: { md: 0, xs: 1 } }} >
                            <Box sx={{ p: 2,  borderRadius: '16px', mx: { md: 2, xs: 0 }, height: '100%', pr: { md: 6, xs: 2 } }} >
                                <Grid container>
                                    {
                                        catAbout?.slice(0,2)?.map((res) =>
                                            <Grid item md={12} xs={12} key={res?.id}>
                                                <Item
                                                    img={res?.img}
                                                    title={res?.title}
                                                    desc={res?.desc}
                                                    bgColor={res?.id === 2 ? '' : '#F12020'}
                                                    color={res?.id === 2 ? '#000' : '#fff'}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ my: { md: 0, xs: 1 } }} >
                            <Box sx={{ p: 2, background: '#F3F3F3', borderRadius: '16px', mx: { md: 2, xs: 0 }, height: '100%', }}>
                                <Form />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default FormContactPage