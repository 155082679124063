import React from 'react'
import Header from '../components/glopal/Header'
import FormContactPage from '../components/ContactPage/FormContactPage'

const ContactUs = () => {
    return (
        <>
            <Header url={'Contact Us'} />
            <FormContactPage />
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d392517.9720517318!2d-104.89533200000001!3d39.7722859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa141076d710a5ca9%3A0x6160c5d20077a5ae!2sGood%20Hands%20Towing!5e0!3m2!1sen!2seg!4v1715522693946!5m2!1sen!2seg" width="100%" height="600" title={'test'} style={{ border: 0, marginTop: '16px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}

export default ContactUs