import { Box, Container } from '@mui/material'
import React from 'react'
import bg from './../../assets/services/17.jpg'
import Form from './Form/Form'
import ContactData from './ContactData'

const ContactUsHome = () => {
    return (
        <>
            <Box position={'relative'} sx={{ backgroundImage: `url(${bg})`, height: { md: '35rem', xs: '65rem' }, display: 'flex', alignItems: 'center', backgroundSize: 'cover', position: 'relative', backgroundAttachment: 'fixed', py: 1 }} >
                <Box className="overlay">
                    <Container maxWidth={'lg'} sx={{ height:'100%' }} >
                        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', alignItems: 'center', py: 1, flexDirection: { md: 'row', xs: 'column-reverse' } } }>
                            <ContactData />
                            <Box sx={{ py: 1, px: 2, borderRadius: '16px', my: 2, mx: { md: 2, xs: 0 }, width: { md: '60%', xs: '100%' }, height: '100%' }}>
                                <Form />
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default ContactUsHome