import { Box, CardMedia, Divider, Typography } from '@mui/material'
import React from 'react'
import { contact } from '../../../constants'
// import res from './../../../assets/carfax.png'
// import res1 from './../../../assets/carstory.png'
const Contact = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', p: 1 }} >
                <Typography color={'#ce4114'} variant='h6' >Contact Info</Typography>
                <Divider sx={{ borderColor: '#ce4114', borderWidth: '2px', width: '5rem', mb:1 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column',alignItems:'flex-start', gap:3 }} >
                    {
                        contact?.map((item, i) =>
                            <Box key={i} sx={{ display: 'flex', alignItems: 'flex-start',  flexDirection:'row' }} >
                                <Box sx={{ display:'flex', alignItems:'center', gap:2 }} >
                                    <CardMedia
                                        component={'img'}
                                        src={item.src}
                                        sx={{ width: '25px', height: '25px', objectFit: 'contain' }}
                                    />
                                    <Typography color={'#fff'} variant='h6' >{item.title}</Typography>
                                </Box>
                                <Typography color={'#fff'}>{item.text}</Typography>
                            </Box>
                        )
                    }
                    {/* <CardMedia 
                    component={'img'}
                    src={res1}/> */}
                    {/* <CardMedia 
                    component={'img'}
                    src={res}/> */}
                </Box>
            </Box>
        </>
    )
}

export default Contact