import React from 'react'
import Header from '../components/glopal/Header'
import About from '../components/AboutUs/Index'
const AboutUs = () => {
    return (
        <>
            <Header url={'About Us'} />
            <About />
        </>
    )
}

export default AboutUs