import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Card from './Card'
import img from './../../assets/Ellipse 40.png'
const ListMeets = () => {
    return (
        <>
            <Container maxWidth={'lg'}>
                <Box py={4} >
                    <Typography variant='h6' color={'secondary.main'} textAlign={'center'} >MEET THE STAFF OF Aurora Motors .</Typography>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Card
                                name={'Aurora Motors Auto. - Friendly Staff'}
                                date={'720-345-9996 -'}
                                img={img}
                                meetDesc={`From the moment you step on our lot until the time you drive away in your next car, your buying experience with Aurora Motors Auto. is 
our number one priority. We encourage you to use the expertise and resources of our sales staff; hey are here to help you find the right car 
at the right price. Call us today at 720-345-9996 to schedule your test drive!`}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card
                                name={'Aurora Motors Auto. - Friendly Staff'}
                                date={'720-345-9996 -'}
                                img={img}
                                meetDesc={`From the moment you step on our lot until the time you drive away in your next car, your buying experience with Aurora Motors Auto. is 
our number one priority. We encourage you to use the expertise and resources of our sales staff; hey are here to help you find the right car 
at the right price. Call us today at 720-345-9996 to schedule your test drive!`}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default ListMeets