import './App.css';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ScrollTo from './components/glopal/ScrollTo';
import NavBar from './components/NavBar';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
// import Reviews from './pages/Reviews';
import Services from './pages/Services';
import Meets from './pages/Meets';
import RebuiltTitles from './pages/RebuiltTitles';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUs';
function App() {

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/reviews" element={<Services />} />
        {/* <Route path="/inventory" element={<Services />} /> */}
        <Route path="/meets" element={<Meets />} />
        <Route path="/Rebuilt-Titles" element={<RebuiltTitles />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
