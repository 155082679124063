
import c1 from './../assets/contacts/Icon awesome-address-card.png'
import c2 from './../assets/contacts/Icon zocial-email.png'
import c3 from './../assets/contacts/Icon awesome-phone-square-alt.png'

import social1 from './../assets/Social/Icon awesome-facebook-f.png'
import social2 from './../assets/Social/Icon awesome-instagram.png'
import social3 from './../assets/Social/Icon awesome-linkedin-in.png'
import social4 from './../assets/Social/Icon awesome-twitter.png'

import contactFooter1 from './../assets/contactFooter/Icon awesome-phone-square-alt.png'
import contactFooter2 from './../assets/contactFooter/Path 34.png'

import car1 from './../assets/cars/n1.jpg'
import car2 from './../assets/cars/n2.jpg'
import car3 from './../assets/cars/n3.jpg'
import car4 from './../assets/cars/n4.jpg'
import car5 from './../assets/cars/n5.jpg'
import car6 from './../assets/cars/n6.jpeg'
import car7 from './../assets/cars/n7.jpeg'
import car8 from './../assets/cars/n8.jpeg'
import car9 from './../assets/cars/n9.jpeg'

import cat1 from './../assets/cat/structure.png'
// import cat2 from './../assets/cat/structure3.png'
// import cat3 from './../assets/cat/structure2.png'
import cat4 from './../assets/cat/structure1.png'

import cleander from './../assets/contacts/cleander.png'
import email from './../assets/contacts/email.png'
import phone from './../assets/contacts/phone.png'

export const Pages = [
        {
                path: `/`,
                name: `الرئيسية`,
                name_en: `Home`
        },
        {
                path: `about-us`,
                name: `من نحن`,
                name_en: `About Us`
        },
        {
                path: `reviews`,
                name: `المراجعات`,
                name_en: `Reviews`
        },
        {
                path: `contact-us`,
                name: `تواصل معنا`,
                name_en: `contact us`
        },
]
export const categories = [
        {
                id: 1,
                img: cat1,
                title: `Expert Towing Staff`,
                desc: `Our staff is expert in towing
 small to large or luxurious auto.`
        },
        {
                id: 4,
                img: cat4,
                title: `24/7 Support`,
                desc: `We are providing 100% 
satisfaction guaranteed service.`
        },
]
export const catAbout = [
        {
                id: 1,
                img: cleander,
                title: `24x7 Available`,
                desc: `Book an Appointment`
        },
        {
                id: 2,
                img: email,
                title: `Email`,
                desc: `Goodhands2024@gmail.com `
        },
        {
                id: 3,
                img: phone,
                title: `Emergency Calls 24/7`,
                desc: `+7205036983`
        },
]
export const contacts = [
        {
                id: 1,
                imageUrl: c1,
                name: `Address`,
                supName: `Office 48, near Dubai police General 
HQ,hor Al hanz East,, Dubai-UAE`,
                nameAr: `Address`,
                supNameAr: `Office 48, near Dubai police General 
HQ,hor Al hanz East,, Dubai-UAE`,
        },
        {
                id: 2,
                imageUrl: c2,
                name: `Email`,
                supName: `Goodhands2024@gmail.com `,
                nameAr: `Email`,
                supNameAr: `Goodhands2024@gmail.com `,
        },
        {
                id: 3,
                imageUrl: c3,
                name: `Phone`,
                supName: `+7205036983`,
                nameAr: `Phone`,
                supNameAr: `+7205036983`,
                desc: "+7205036983"
        },
];

export const socials = [
        {
                id: 1,
                src: social1,
                link: 'https://www.facebook.com/profile.php?id=61556338452849'
        },
        {
                id: 2,
                src: social2,
                link: '/'
        },
        {
                id: 3,
                src: social3,
                link: '/'
        },
        {
                id: 4,
                src: social4,
                link: '/'
        },
]
export const contact = [
        {
                id: 1,
                src: contactFooter2,
                text: 'Goodhands2024@gmail.com  '
        },
        {
                id: 1,
                src: contactFooter1,
                text: '+7205036983'
        },
]

export const services = [
        {
                id: 1,
                img: car1,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 2,
                img: car2,
                title: 'Towing Service',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 3,
                img: car3,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 4,
                img: car4,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 5,
                img: car5,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 6,
                img: car6,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 7,
                img: car7,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 8,
                img: car8,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
        {
                id: 9,
                img: car9,
                title: 'Roadside Assistance',
                desc: `We are your #1 source for towing. We 
offer reliable services in Denver, Colorado.
Our local & long distance auto towing Denver
capabilities is designed for vehicles of all shapes
sizes and makes.`
        },
]

export const data = [
        {
                title: `Privacy`,
                desc: `Protecting your private information is our priority and we are committed to safeguarding your privacy online. This Privacy Policy explains what personal information we may collect about you through our website, when we collect it, how we may use it, who we may disclose it to, and is intended to help you understand the terms and conditions surrounding the collection and use of your data.`
        },
        {
                title: `Use of Cookies`,
                desc: `Our website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.`
        },
        //         {
        //                 title:`How Your Personal Information Is Collected`,
        //                 desc:`We collect Personal Information that you voluntarily give or has been given through some other direct contact from you. When you access our website
        // , we may also passively or automatically collect additional Personal Information and non-personally identifiable data regarding
        //  the means you use to access our website and the content you view when you use our website, for example, via cookies on our website.`
        //         },
        //         {
        //                 title:`Requests for Unit Quotes`,
        //                 desc:`If you request a unit price quote, you may be asked to provide Personal Information such as your name, address, telephone number, zip code, email address
        //  and other transactional information related to the desired unit purchase. Your Personal Information may be used to contact you with
        //  the requested quote or to request additional information. While not required, you may voluntarily provide financial information so that a determination can b
        // e made regarding units that meet the parameters of your requested unit quote or your pre-qualification for financing, if interested`
        //         },
        //         {
        //                 title:`Monthly Payment Calculations`,
        //                 desc:`If you request a unit price quote, you may be asked to provide Personal Information such as your name, address, telephone number, zip code, email address
        //  and other transactional information related to the desired unit purchase. Your Personal Information may be used to contact you with
        //  the requested quote or to request additional information. While not required, you may voluntarily provide financial information so that a determination can b
        // e made regarding units that meet the parameters of your requested unit quote or your pre-qualification for financing, if interested`
        //         },
        //         {
        //                 title:`Requests for Unit Quotes`,
        //                 desc:`If you use the digital retailing tool to calculate your estimated monthly payment, you are not required to provide Personal Information unless you decide to 
        // contact the dealer to obtain additional information about the unit you are interested in. If you decide to contact the dealer, you will be asked to provide your
        //  name, email address, shipping address and phone number. Your Personal Information will be used to contact you regarding your inquiry.`
        //         },
        //         {
        //                 title:`Log Files`,
        //                 desc:`We use IP addresses to analyze trends, administer the site, track user's movement, and gather broad demographic and geographic information for aggreg
        // ate use. IP addresses are only linked to Personal Information if you submit a form requesting additional information.`
        //         },
        //         {
        //                 title:`Surveys and Contests`,
        //                 desc:`From time to time, this website may offer contests or request information from you via surveys to conduct research about your opinion of current services
        //  or of potential new services that may be offered. Participation in these surveys or contests is completely voluntary and you are not obligated to disclose
        //  the Personal Information requested such as your name, email address, mailing address, or demographic information (i.e., age, zip code). If you elect to
        //  participate in a contest, the contact information provided will only be used to notify the winners and award prizes. Survey information will only be used
        //  for purposes of monitoring or improving the use and satisfaction of this website`
        //         },
        //         {
        //                 title:`Use of Your Personal Information`,
        //                 desc:`We may use or disclose the Personal Information we collect for one or more of the following business purposes`
        //         },
        //         {
        //                 title:`Use of Cookies`,
        //                 desc:` Our website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. 
        // Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server
        //  in the domain that issued the cookie to you Cookies can be used for various purposes such as to detect whether your device has accessed a particular online service before. We use cookies on pages 
        // to make your visit to our website more enjoyable and to enable the use of certain functions, including the ability to identify units that have been previously 
        // viewed on your device Our website may access or use YouTube API Services. By using these services, you agree to be bound by YouTube’s Terms of Service and the Google
        //  Privacy Policy. Use of YouTube’s API Services through our Site may result in the creation and storage of a token allowing the upload of videos for our users
        // . Users will log into Google API’s using the OAuth 2.0 protocol. After logging in, the user may grant permission for Dealer Car Search to access Authorized 
        // Data in the form of a token that permits Dealer Car Search access to the user's video feed for the purpose of posting user videos. Users can revoke access 
        // to their Authorized Data via the Google security settings page. Users may contact privacy@leadventure.com with any questions or concerns about Dealer 
        // Car Search’s privacy practices`
        //         },
]