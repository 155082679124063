import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import img from './../../assets/RebuiltTitles/Group 2894.png'

const Details = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ py:4 }} >
                    <Typography variant='h6' color={'secondary.main'} textAlign={'center'} my={1} >MEET THE STAFF OF Aurora Motors .</Typography>
                    <Box sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, flexDirection:
                            { md: 'row', xs: 'column' }
}} >
                        <Box sx={{ width: {md:'40%', xs:'100%'} }} >
                            <CardMedia
                                component={'img'}
                                src={img}
                            />
                        </Box>
                        <Box sx={{ width: {md:'60%', xs:'100%'}, display: 'flex', flexDirection: 'column', gap: 2 }} >
                            <Typography variant='h5' >What is a rebuilt title?</Typography>
                            <Typography variant='h6' >REBUILT TITLES</Typography>
                            <Typography>A rebuilt title refers to a type of title given to a vehicle that has been previously
                                salvaged due to damage or theft. When a vehicle is considered a total loss by an I
                                nsurance company, the title is branded as salvage, which means that the vehicle
                                cannot be driven legally on the roads until it has been repaired and inspected
                                by a state agency.
                                Once the repairs have been completed, the vehicle can be inspected by the state
                                agency, and if it passes the inspection, the title will be rebranded as rebuilt. A rebuilt
                                title indicates that the vehicle was once salvaged but has since been restored
                                to a drivable condition.</Typography>
                            <Typography variant='h6' >Advantages:</Typography>
                            <ul style={{ margin:0 }} >
                                <li>Lower cost: A vehicle with a rebuilt title is typically priced lower than a similar vehicle
                                    with a clean title, making it a more affordable option for buyers on a budget.</li>
                                <li>Repaired damage: A rebuilt title means that the vehicle has undergone repairs and
                                    a thorough inspection to ensure that it is safe to drive.</li>
                                <li>Unique options: Sometimes, rebuilt vehicles can offer unique options that are not
                                    available in other vehicles, making them appealing to buyers who are looking for a
                                    specific feature set.</li>
                            </ul>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Details