import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Card from './Card'
import img from './../../assets/reviews/Screenshot 2024-07-16 105940.png'
import img1 from './../../assets/reviews/Screenshot 2024-07-16 105931.png'
import img2 from './../../assets/reviews/Screenshot 2024-07-16 105949.png'
import img3 from './../../assets/reviews/Screenshot 2024-07-16 111109.png'
import img4 from './../../assets/reviews/Screenshot 2024-07-16 110000.png'
import img5 from './../../assets/reviews/Screenshot 2024-07-16 110011.png'
// import Btn from '../glopal/Btn'
const ListReviews = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box py={2} >
                    <Box p={2} width={'100%'} >
                        <Typography variant='h5' textAlign={'center'} color='#ce4114'>Here's what our customers say</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Card img={img}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card img={img1}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card img={img2}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card img={img3}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card img={img4}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Card img={img5}/>
                        </Grid>
                    </Grid>
                </Box>
                
            </Container>
        </>
    )
}

export default ListReviews