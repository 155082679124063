import React from 'react'
import './Header.css'
import { Box, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import { useNavigate } from 'react-router-dom'
const Header = ({ url }) => {

    const navigate = useNavigate()

    return (
        <div className='header' >
            <div className="overlay"></div>
            <Box sx={{ position: 'absolute', zIndex: '9', left:'10%' }}>
                <Box sx={{ display: 'flex', color: '#FFF', justifyContent: 'center', gap: 1 }} >
                    <Typography onClick={() => navigate('/')} sx={{ cursor:'pointer' }} >{'Home'}</Typography>
                    <EastIcon sx={{ color: '#FABB25' }} />
                    <Typography>{url}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Header