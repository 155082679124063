import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

const Title = ({ title }) => {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: { md: 2 ,xs:1}, justifyContent:'center', my:1 }} >
                <Divider sx={{ borderColor: '#ce4114', borderWidth: '2px', width: {md:'5rem',xs:'3rem'} }} />
                <Typography variant='h6' color={'#fff'} >{title}</Typography>
                <Divider sx={{ borderColor: '#ce4114', borderWidth: '2px', width: {md:'5rem',xs:'3rem'} }} />
            </Box>
        </div>
    )
}

export default Title