import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from "notistack";
import theme from "./util/theme";
import { Provider as ReduxProvider } from 'react-redux'; 
import { PersistGate } from 'redux-persist/lib/integration/react';
// redux
import { store, persistor } from './redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </BrowserRouter>
  </React.StrictMode>
);