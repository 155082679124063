import { Box, CardMedia } from '@mui/material'
import React from 'react'
import img from './../../../assets/logo.png'
import ListSocial from './ListSocial'
// import ListSocial from './ListSocial'
const Logo = () => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', p: 1, justifyContent: 'center', height: '100%' }} >
        <CardMedia
          component={'img'}
          src={img}
          sx={{ width: '50%' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '50%' }} >
          <ListSocial />
        </Box>
      </Box>
    </>
  )
}

export default Logo