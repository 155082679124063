import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const api = createApi({
  baseQuery: fetchBaseQuery({
    mode: 'cors',
    baseUrl: 'https://aurora.revampbrands.com/api/v1',
    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "application/json") 
      headers.set("Cache-Control", "no-cache");
      return headers
    } 
  }),
  reducerPath: "apiHome",
  tagTypes: ["basicData", 'homeSlider', 'homeAboutUsSection', 'brands'],
  endpoints: (build) => ({
    getBrandData: build.query({
      query: () => `/brands`,
      providesTags: ["brands"],
    }),
    getBasicData: build.query({
      query: () => `/basicData`,
      providesTags: ["basicData"],
    }),
    getHomeSlider: build.query({
      query: () => `/homeSlider`,
      providesTags: ["homeSlider"],
    }),
    getHomeAboutUsSection: build.query({
      query: () => `/homeAboutUsSection`,
      providesTags: ["homeAboutUsSection"],
    }),
  }),
});


export const {
  useGetBrandDataQuery,
  useGetBasicDataQuery,
  useGetHomeSliderQuery,
  useGetHomeAboutUsSectionQuery,
} = api;