import React from 'react'; 
import { Box, Container, Grid } from '@mui/material';
// import Search from '../glopal/Search';
// import { useGetBrandDataQuery } from '../../state/apiHome';
import { categories } from '../../constants';
import Item from './Item';
import HeroText from '../SliderHome/HeroText';


const Category = () => {

  

  // const { data, isBrandsLoading } = useGetBrandDataQuery();

  // const [tableData, setTableData] = useState([]);
  // useEffect(() => {
  //   if (data && !isBrandsLoading) {
  //     setTableData(data?.data)
  //   }
  // }, [data, tableData, isBrandsLoading])

  return (
    <>
      <Box sx={{ py: 2, position: 'relative', zIndex: 9 }}>
        <Container maxWidth={'lg'} >
        <HeroText title={`Good at towing  `} desc={`Reliable 24×7 Towing Service in Denver and Roadside Assistance 
Service.We provide small to big size tow trucks service near Denver `} btn={`Read More`} />
          {/* <Grid container spacing={2} >
            {
              categories?.map((res) =>
                <Grid item md={6} xs={12} key={res?.id}>
                  <Item 
                    img={res?.img}
                    title={res?.title}
                    desc={res?.desc}
                  />
                </Grid>
              )
            }
          </Grid> */}
        </Container>
      </Box>
    </>
  );
};

export default Category;
