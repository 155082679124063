import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Container, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import logo from './../../assets/logo.png'
import './Nav.css'
import { Pages } from "../../constants";
// import TopNav from "./TopNav";

function NavBar() {
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            {/* <TopNav /> */}
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: '#171717',
                    padding: "0 10px",
                    // position: 'absolute',
                    // zIndex: 999
                }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 5,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'space-between'
                            }}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    marginInlineStart: 8
                                }}>
                                <img src={logo} alt="logo" className="nav-logo" />
                            </Link>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    width: '60%'
                                }}>
                                {Pages?.map((page, i) => (
                                    page.name_en !== 'privacy' &&
                                    <Link
                                        key={i}
                                        to={page.path}
                                        style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold'
                                        }}
                                        className="link"
                                    >
                                        {page.name_en}
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            gap: { md: '65px', xs: 1 },
                            width: { md: 'fit-content', xs: '100%' },
                            alignItems: 'center'
                        }} >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        marginInlineStart: 8
                                    }}>
                                    <img src={logo} height={'7rem'} alt="logo" className="nav-logo" ></img>
                                </Link>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '3rem', color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
