import { Box } from '@mui/material'
import React from 'react'
import Sliders from './Sliders'
import HeroText from './HeroText'

const SliderHome = () => {
  return (
    <>
      <Box position={'relative'}>
        <div className="overlay"></div>
          <Sliders />
        {/* <HeroText title={`Good at towing  `} desc={`Reliable 24×7 Towing Service in Denver and Roadside Assistance 
Service.We provide small to big size tow trucks service near Denver `} btn={`Read More`} /> */}
      </Box>
    </>
  )
}

export default SliderHome