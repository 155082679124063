import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { catAbout } from '../../constants'
import Item from './Item'
import Title from '../glopal/Title'

const About = () => {
    return (
        <>
            <Box sx={{ mt: { md: '-5rem', xs: '-3rem' }, pb: 2, position: 'relative', zIndex: 9 }}>
                <Container maxWidth={'lg'} >
                    <Grid container spacing={2}>
                        {
                            catAbout?.map((res) =>
                                <Grid item md={4} xs={12} key={res?.id}>
                                    <Item
                                        img={res?.img}
                                        title={res?.title}
                                        desc={res?.desc}
                                        bgColor={res?.id === 2 ? '' :'#F12020' }
                                        color={res?.id === 2 ? '#000' :'#fff' }
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: { md: 1, xs: 1 }, flexDirection: { md: 'column', xs: 'column' }, py: 2 }} >
                        <Title title={'About Us'} />
                        <Typography sx={{ color: '#939494', textAlign: 'center' }} >
                            We are a towing company located in Denver, Co. We are proud to say that we have been providing Towing Trucks and Roadside Assistance
                            services in Denvefor Long Time. Good at towing  offers 24 hours towing in Denver and its surrounding areas, road side
                            assistance, and automotive services. No matter what the issue is, We will be happy to send out a fully equipped tow truck to you with a trained and
                            professional technician to assist you.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default About