import React from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Typography } from '@mui/material'
import { data } from '../constants'

const Privacy = () => {
  return (
    <>
      <Header url={'Privacy'} />
      <Container maxWidth={'lg'} >
        <Box sx={{ display: 'flex', py: 4, flexDirection: 'column', gap: 1 }} >
          {data?.map((res) =>
            <>
              <Typography variant='h6' color={'secondary.main'} >{res.title}</Typography>
              <Typography>{res.desc}</Typography>
            </>
          )}
          {/* <Typography variant='h6' >Advantages:</Typography>
          <ul >
            <li>Lower cost: A vehicle with a rebuilt title is typically priced lower than a similar vehicle
              with a clean title, making it a more affordable option for buyers on a budget.</li>
            <li>Repaired damage: A rebuilt title means that the vehicle has undergone repairs and
              a thorough inspection to ensure that it is safe to drive.</li>
            <li>Unique options: Sometimes, rebuilt vehicles can offer unique options that are not
              available in other vehicles, making them appealing to buyers who are looking for a
              specific feature set.</li>
            <li>Lower cost: A vehicle with a rebuilt title is typically priced lower than a similar vehicle
              with a clean title, making it a more affordable option for buyers on a budget.</li>
            <li>Repaired damage: A rebuilt title means that the vehicle has undergone repairs and
              a thorough inspection to ensure that it is safe to drive.</li>
            <li>Unique options: Sometimes, rebuilt vehicles can offer unique options that are not
              available in other vehicles, making them appealing to buyers who are looking for a
              specific feature set.</li>
            <li>Lower cost: A vehicle with a rebuilt title is typically priced lower than a similar vehicle
              with a clean title, making it a more affordable option for buyers on a budget.</li>
            <li>Repaired damage: A rebuilt title means that the vehicle has undergone repairs and
              a thorough inspection to ensure that it is safe to drive.</li>
            <li>Unique options: Sometimes, rebuilt vehicles can offer unique options that are not
              available in other vehicles, making them appealing to buyers who are looking for a
              specific feature set.</li>
            <li>Lower cost: A vehicle with a rebuilt title is typically priced lower than a similar vehicle
              with a clean title, making it a more affordable option for buyers on a budget.</li>
            <li>Repaired damage: A rebuilt title means that the vehicle has undergone repairs and
              a thorough inspection to ensure that it is safe to drive.</li>
            <li>Unique options: Sometimes, rebuilt vehicles can offer unique options that are not
              available in other vehicles, making them appealing to buyers who are looking for a
              specific feature set.</li>
          </ul>
        */}
        </Box>
      </Container>
    </>
  )
}

export default Privacy